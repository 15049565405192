import Context from 'context';
import Backbone from 'backbone';
import AQ from 'ardoq';
import BasicModel from 'models/basicmodel';
import { logError } from '@ardoq/logging';
import { getModelUrl } from './utils/scenarioUtils';
import { getApiUrl } from 'backboneExtensions';
import { EVENT_TAG_ADDED, EVENT_TAG_REMOVED } from 'collections/consts';
import type {
  BasicModel as BasicModelType,
  ComponentBackboneModel,
  Reference,
  Tag as TagBackboneModel,
} from 'aqTypes';
import type { ArdoqId } from '@ardoq/api-types';
import { TAG_NAME_VALIDATION_MESSAGE, TAG_REGEX } from 'models/consts';
import { difference, each, includes } from 'lodash';

const replaceTagInDescription = (
  model: BasicModelType,
  oldName: string,
  newName: string
) => {
  const currentDescription = model.getDescription();
  const regexp = new RegExp(` ${oldName}([^a-zd_-æøåäöëèé])*`, 'g');
  if (currentDescription) {
    const newDescription = currentDescription.replace(regexp, ` ${newName}$1`);
    if (currentDescription !== newDescription) {
      model.set('description', newDescription);
      return true;
    }
  }
  return false;
};

function updateOnChangedObjects(
  tag: TagBackboneModel,
  objects: ArdoqId[],
  objectField: 'components' | 'references',
  globalCollection: Backbone.Collection<BasicModelType>
) {
  if (tag.hasChanged(objectField)) {
    const oldObjects = tag.previous(objectField),
      deleted = difference(oldObjects, objects),
      added = difference(objects, oldObjects);

    deleted.forEach(function (id) {
      const obj = globalCollection.get(id);
      tag.replaceTagOccurrences(`#${tag.name()}`, tag.name(), obj);
      globalCollection.trigger(EVENT_TAG_REMOVED, obj, tag);
    });
    added.forEach(function (id) {
      const obj = globalCollection.get(id);
      globalCollection.trigger(EVENT_TAG_ADDED, obj, tag);
    });
  }
}

type TagClass = TagBackboneModel & {
  new (props?: Record<string, any>): TagBackboneModel;
};
const Tag: TagClass = BasicModel.extend({
  idAttribute: '_id',
  urlRoot: `${getApiUrl(Backbone.Model)}/api/tag`,
  url: function (this: TagBackboneModel) {
    return getModelUrl(this.urlRoot, this.isNew(), this.id);
  },
  mustBeSaved: false,
  defaults: {
    name: '',
    description: '',
    components: null,
    references: null,
  },
  validation: {
    name: {
      pattern: TAG_REGEX,
      msg: TAG_NAME_VALIDATION_MESSAGE,
    },
  },
  initialize: function (this: TagBackboneModel) {
    this.initArrayIfEmpty('components');
    this.initArrayIfEmpty('references');
    const contextWorkspaceId = Context.activeWorkspaceId();
    if (contextWorkspaceId && !this.get('rootWorkspace')) {
      this.set('rootWorkspace', contextWorkspaceId);
    }

    this.on('change', function (this: TagBackboneModel) {
      this.mustBeSaved = true;
    });
    this.on('change:name', (model: TagBackboneModel, name: string) => {
      this.replaceTagOccurrences(`#${this.previous('name')}`, `#${name}`);
    });
    this.on('destroy', (model: TagBackboneModel) => {
      this.replaceTagOccurrences(`#${model.name()}`, model.name());
    });

    this.on(
      'change:components',
      (tag: TagBackboneModel, components: ArdoqId[]) => {
        updateOnChangedObjects(
          tag,
          components,
          'components',
          AQ.globalComponents
        );
      }
    );
    this.on(
      'change:references',
      (tag: TagBackboneModel, references: ArdoqId[]) => {
        updateOnChangedObjects(tag, references, 'references', AQ.references);
      }
    );

    this.on(
      'sync',
      function (this: TagBackboneModel) {
        this.mustBeSaved = false;
      },
      this
    );
  },

  initArrayIfEmpty: function (
    this: TagBackboneModel,
    key: 'components' | 'references'
  ) {
    if (!this.get(key)) {
      this.set(key, []);
    }
  },
  getAttributeName: function (entity: ComponentBackboneModel | Reference) {
    if (entity.urlRoot === '/api/component') {
      return 'components';
    }
    return 'references';
  },

  tagEntity: function (
    this: TagBackboneModel,
    entity: ComponentBackboneModel | Reference
  ) {
    const attributeName = this.getAttributeName(entity);
    const existing = this.get(attributeName);
    if (!includes(existing, entity.id)) {
      this.set(attributeName, [...new Set([...existing, entity.id])]);
      this.trigger('change', this);
    }
  },
  remove: function (
    this: TagBackboneModel,
    entity: ComponentBackboneModel | Reference
  ) {
    const attributeName = this.getAttributeName(entity);
    const collection: string[] = this.get(attributeName);
    if (collection) {
      this.set(
        attributeName,
        collection.filter(id => id !== entity.id)
      );
      this.trigger('change', this);
    }
  },
  add: function (
    this: TagBackboneModel,
    obj: ComponentBackboneModel | Reference
  ) {
    if (!obj) {
      return null;
    }
    if (!obj.id && obj.once) {
      obj.once('sync', () => this.add(obj));
    } else {
      this.tagEntity(obj);
    }
  },
  contains: function (
    this: TagBackboneModel,
    entity: ComponentBackboneModel | Reference
  ) {
    const attributeName = this.getAttributeName(entity);
    const collection: string[] = this.get(attributeName);
    return Boolean(collection?.some(id => id === entity.id));
  },

  getIdsOfComponentsWithTag: function (this: TagBackboneModel): string[] {
    return this.get('components');
  },
  getIdsOfReferencesWithTag: function (this: TagBackboneModel): string[] {
    return this.get('references');
  },
  name: function (this: TagBackboneModel): string {
    return this.get('name');
  },
  replaceTagOccurrences: function (
    this: TagBackboneModel,
    inOldName: string,
    newName: string,
    obj?: BasicModelType
  ) {
    const oldName = inOldName.replace(/([.?*+()[]])/g, '\\$1');

    const actualNewName =
      newName === oldName.substr(1) ? `**${newName}**` : newName;
    if (obj) {
      replaceTagInDescription(obj, oldName, actualNewName);
    } else {
      const components = this.getIdsOfComponentsWithTag();
      const references = this.getIdsOfReferencesWithTag();
      each(components, function (id) {
        const comp = AQ.globalComponents.get(id);
        if (!comp) {
          logError(Error('Component not found.'), null, { id });
          return;
        }
        replaceTagInDescription(comp, oldName, actualNewName);
      });
      each(references, function (id) {
        const ref = AQ.references.get(id);
        if (!ref) {
          logError(Error('Reference not found.'), null, { id });
          return;
        }
        replaceTagInDescription(ref, oldName, actualNewName);
      });
    }
  },
});
function createTag(props?: Record<string, any>) {
  return new Tag(props);
}

export default {
  model: Tag,
  create: createTag,
};
